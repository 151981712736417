@logo-width: 73px;
@logo-height: 53px;
@logo-width-mobile: 44px;
@logo-height-mobile: 32px;
@logo-margin: 20px;
@logo-margin-mobile: 14px;
@navbar-height: @logo-height + 2*@logo-margin;
@navbar-height-mobile: @logo-height-mobile + 2*@logo-margin-mobile;
@navbar-bottom: 100px;

.navbar-default{
	background-color: white;
	border: 0;

	.container-fluid{
		position: relative;
	}

	.navbar-nav{
		margin: 0;

		.submenu-item{
			color: black;
		}

		li{
			display: inline-block;

			a {
				line-height: @navbar-height-mobile; // logo-height + margin * 2
				padding: 0 15px;

				&:hover{
					color: black;
				}

				&:before{
					content: "";
					position: absolute;
					width: 0;
					height: 2px;
					bottom: 18px;
					margin-left: 10%;
					left: 0;
					background-color: #000;
					visibility: hidden;
					// -webkit-transform: scaleX(0);
					// transform: scaleX(0);
					-webkit-transition: all 0.3s ease-in-out 0s;
					transition: all 0.3s ease-in-out 0s;
				}

				&:hover:before{
					visibility: visible;
					width: 80%;
					// -webkit-transform: scaleX(1);
					// transform: scaleX(1);
				}
			}
		}
	}
}

#mainNav-shadowbox{
	box-shadow: 0 2px 24px rgba(0,0,0, 0.14);
	padding: 0;

	position: fixed;
	bottom: 0;
	height: @navbar-height-mobile;
	z-index: 1050;
	background-color: white;
	transition: all 0.4s cubic-bezier(0.4, 0, 0.2, 1);//ease;

	#mainNav-clip{
		position: absolute;
		width: 100%;
		height: 100%;
		clip: rect(0, auto, auto, 0);

		.logoOutline{
			position: fixed;
			top: 22%;
			left: 40%;
			width: 580px;

			polygon{
				stroke: white;
				fill: transparent;
			}
		}

		#mainNav{
			background-color: transparent;

			#menuButton{
				font-family: @serifFont;
				.getFontSize(5);
				color: black;
				text-transform: initial;
				width: inherit;
			}

			#hamburgerIcon{
				display: inline-block;
				margin-right: 7px;

				svg{
					width: 18px;
					height: 14px;
				}
			}

			.fullSizeButton{
				box-sizing: border-box;
				height: @navbar-height-mobile - 2px*6px;
				margin-top: 6px;
				margin-right: -9px;
				width: initial;
			}
		}
	}
}



#logo {
	height: @logo-height-mobile;
	width: @logo-width-mobile;
	margin: @logo-margin-mobile auto;
	display: block;
}

.navbar-brand{
	width: 100%;
}

.navbar-header{
	float: none;
	display: block;
}

/* FULL SCREEN MODAL STYLES
   -------------------------------------------------- */
.fullscreen.modal{

	#menuButtonClose{
		padding-left: 0;
		text-align: left;
		min-width: 200px;

		svg{
			width: initial;
		}
	}

	.modal-dialog {
		position: absolute;
		bottom: 50px;
		margin: 100px 0 0 @col-md-offset-1;
		width: @col-md-11;
		padding: 0 0 0 1.7%;
		color: #333;
		-webkit-transform: translate3d(0, 0, 0);
		-ms-transform: translate3d(0, 0, 0); // IE9 only
		transform: translate3d(0, 0, 0);

		// -webkit-transform: translate(0, 0);
		// -ms-transform: translate(0, 0); // IE9 only
		// transform: translate(0, 0);
	}

	.modal-content {
		height: 100%;
		min-height: 100%;
		border-radius: 0;
		color: #333;
		background: transparent;
		//overflow:auto;

		box-shadow: none;
		border: 0;
	}

	.modal-header {
		border: 0;
	}

	.modal-body {
		ul {
	   		padding: 0 0 0 0;
			list-style: none;
		}
		li {
			padding: 17px 0 17px 0 ;

		}
		a{
		   color: white;
		   font-size: 2em;
		   &:hover, &:focus{
			   text-decoration: none;
		   }
		}
	}

	.menu-item{
		display: inline-block;
		opacity: 0;
		height: 100%;
		transform: translate3d(0, 0, 0);
		transform: translateY(30px);
		min-width: 80%;
		transition: all 0.3s cubic-bezier(0.22, 0.61, 0.36, 1);

		.socialmedia{
			display: inline-block;
			padding: 0 25px;

			&:first-of-type{
				margin-left: -25px;
			}

			svg{
				width: auto;
				height: 0.6em;
			}

			&:hover path{
				fill: white;
			}
		}
		.inline-route {
			display: inline-block;
			font-size: 16px;
			font-family: @sansSerifFont;
			margin-right: 30px;
			font-weight: 500;
			color: #d9d9d9;
			&:hover {
				color: white;
			}
		}
	}

	&.in {
		.menu-item{
			opacity: 1;
			transform: translateY(0);
		}

		.delay();
	}
}

.delay(@index: 1) when (@index <= 6) {
  .delay((@index + 1));
  li:nth-child(@{index}) .menu-item{
	  transition-delay: unit(((7-@index) * 50),ms);
  }
}

@media screen and (min-width: @col-sm-min){

	.navbar-default .navbar-nav li a{
		line-height: @navbar-height;

		&:before{
			bottom: 30px;
		}
	}

	#mainNav-shadowbox{
		height: @navbar-height;

		#mainNav-clip #mainNav{

			#menuButton{
				font-size: 1.9em;
			}

			#hamburgerIcon {
				margin-right: 16px;

				svg{
					// to fix safari stretching svg to enormous size
					width: 24px;
					height: 18px;
					//width: 100%;
					//height: 100%;
				}
			}
		}
	}
	#logo {
		height: @logo-height;
		width: @logo-width;
		margin: @logo-margin auto;
	}
}

@media screen and (min-width: @col-md-min) and (min-height: 830px){
	#mainNav-shadowbox{
		bottom: @navbar-bottom;

		#mainNav{
			bottom: @navbar-bottom;
		}
	}
	.fullscreen.modal {
		.modal-dialog{
			bottom: 150px; // TODO: find right value
			width: @col-md-11;
		}

		.modal-body {
		   ul {
			   //overflow: auto;
			}
			li {
				padding: 20px 0 20px 0;
		    }
			a {
				font-size: 2.5em;
		    }
		}
	}
}

@media screen and (max-width: @col-sm-min){
	.fullscreen.modal {
		.modal-dialog{
			bottom: 0px; // TODO: find right value
			width: @col-md-11;
		}

		.modal-body {
		   ul {
			   //overflow: auto;
			}
			li {
				padding: 10px 0 10px 0;
			}
			a {
				font-size: 1.5em;
			}
		}
	}
}

.modal-backdrop{
	background-color: @menu-backdrop-color;
	opacity: 0;
	-webkit-transition: opacity .15s linear;
	-o-transition: opacity .15s linear;
	transition: opacity .15s linear;

	&.in{
		opacity: @menu-backdrop-opacity;
	}
}

@media screen and (min-width: @col-sm-min){
	#mainNav-shadowbox #mainNav-clip  #mainNav .fullSizeButton{
		height: @navbar-height - 2px*6px;
	}
}

@media screen and (min-width: @col-md-min) and (max-height: 820px){
	#mainNav-shadowbox{
		bottom: @navbar-bottom/2;

		#mainNav{
			bottom: @navbar-bottom/2;
		}
	}
}
