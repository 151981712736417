#frontpage-carousel {
	height: 100vh;
	width: 100vw;
	position: absolute;
	top: 0;
	right: 0;
	@media screen and (max-width: @col-md-min) {
		height: calc(~'100vh - 60px');
	}
	.carousel-control {
		background-image: none;
		display: none;
		transition: .3s !important;
		&.left,
		&.right {
			line-height: 100vh;
			svg {
				height: 12%;
				min-height: 70px;
			}
		}
		&.right:hover {
			background-image: -webkit-linear-gradient(left,rgba(0,0,0,.0001) 0,rgba(0,0,0,.1) 100%);
			background-image: -o-linear-gradient(left,rgba(0,0,0,.0001) 0,rgba(0,0,0,.1) 100%);
			background-image: -webkit-gradient(linear,left top,right top,from(rgba(0,0,0,.0001)),to(rgba(0,0,0,.1)));
			background-image: linear-gradient(to right,rgba(0,0,0,.0001) 0,rgba(0,0,0,.1) 100%);
		}
		&.left:hover {
			background-image: -webkit-linear-gradient(right,rgba(0,0,0,.0001) 0,rgba(0,0,0,.1) 100%);
			background-image: -o-linear-gradient(right,rgba(0,0,0,.0001) 0,rgba(0,0,0,.1) 100%);
			background-image: -webkit-gradient(linear,right top,left top,from(rgba(0,0,0,.0001)),to(rgba(0,0,0,.1)));
			background-image: linear-gradient(to left,rgba(0,0,0,.0001) 0,rgba(0,0,0,.1) 100%);
		}
		&.shown {
			display: block;
		}
	}
	.carousel-inner {
		height: 100%;
		.item {
			height: 100%;
			.background-image {
				height: 100%;
				-webkit-background-size: cover;
				-moz-background-size: cover;
				-o-background-size: cover;
				background-size: cover;
				background-position: center center;
			}
		}
	}
}