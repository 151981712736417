#contact-us-top {
	margin-bottom: 0px;
}

#contact-us-bottom {
	margin-top: 10px;
}

.adressItem{
	margin-top: 16px;
	margin-bottom: 16px;

	i.icon{
		margin-right: 8px;
	}
}
.contact-form {
	margin-top: 112px;
	#contact-lastname {
		display: none;
	}
	input, textarea {
		font-size: 14px;
		transition: .4s;
		&.error {
			border: 1px solid rgba(178, 34, 34, .5);
		}
	}
}