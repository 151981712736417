@products-backdrop-opacity: 0.39;

.row.last{
	padding-bottom: 25px;
}

.product-item{
	position: relative;
	height: 400px;

	&.first{
		padding-left: 25px;
	}
	&.last{
		padding-right: 25px;
	}

	a{
		position: relative;
		display: block;
		height: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: column;
		color: white;

		&:before{
			background-color: @menu-backdrop-color;
			content: '';
			width: 100%;
			height: 100%;
			display: block;
			position: absolute;
			left: 0;
			top: 0;
			opacity: 0.37;
		}

		.type{
			font-weight: 400;
			position: relative;
			.getFontSize(5);
		}

		.name{
			font-family: @serifFont;
			position: relative;
			.getFontSize(1);
		}
	}

	img{
		width: 100%;
		height: 100%;
	}

	&.unavailable{
		a::before{
			background-color: white;
			opacity: 0.75;
		}

		.name{
			.getFontSize(4);
			color: #5E5E5E;
			opacity: 1;
		}
	}
}

@media screen and (min-width: @col-md-min){
	.row.last{
		padding-bottom: 50px;
	}

	.product-item{
		height: 100%;

		&.first{
			padding-left: 50px;
		}
		&.last{
			padding-right: 50px;
		}

		a{
			&:before, .type, .name{
				opacity: 0;
			}

			&:hover{
				&:before{
					opacity: 0.37;
				}
				.type{
					opacity: 1;
				}
				.name{
					opacity: 1;
				}
			}
		}
	}
}
