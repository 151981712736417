.itemcounter{
	text-align: right;
}

#checkout {
	min-height: 900px;
}

.submenu-item {
	position: relative;
}

@media(max-width:767px) {
	.cart-count-value {
		opacity: 0 !important;
	}
}

.cart-count {
	position: absolute;
	transition: .3s;
	width: 22px !important;
	height: 22px !important;	
	border: 2px solid black;
	border-radius: 50%;
	opacity: 0;
	
	background-color: white;
	right: 15px;
	top: 15px;
	line-height: 20px;
	text-align: center;
	font-size: 12px;
	font-weight: bold;
	pointer-events: none;
}

.cart-count-value {
	opacity: 1;
}

#cart-list {
	margin-top: 50px;
	padding-bottom: 350px;
	overflow-x: hidden;
	.type {
		font-weight: bold;
		margin-bottom: 0px;
	}
	h1 {
		margin-top: 10px;
		font-size: 53px;
	}
	.price {
		margin-top: 30px;
		font-weight: bold;
		font-size: 20px;
	}
	.subtext {
		font-size: 12px;
	}
	.noButtonStyle {
		margin: 10px 0px 30px 0px;
		padding: 0px;
		transition: .3s;
		border-bottom: 0px solid rgba(0,0,0, 0.0);
		padding-bottom: 2px;
		border-radius: 0px !important;
		&:hover,
		&:focus {
			color: black;
			outline: none;
			padding-bottom: 1px;
			border-bottom: 1px solid black;
		}
	}
	.row {
		margin-bottom: 40px;
	}

	.item-image img {
		margin-left: auto;
		margin-right: auto;
		height: 250px !important;
		padding-top: 40px;
	}

	
}

#cartCaroussel{
	margin-bottom: 1.5em;

	.item{
		text-align: center;
		outline: none; // remove focus outline

		.item-image svg{
			height: 60px;
			display: block;
			margin: 0 auto;
			&>g{
				// to avoid clipped strokes (especially on mobile)
				transform: scale(0.95) translate(11px,21px);
				//transform-origin: center;
			}
		}

		.description{
			display: inline-block;
			text-align: left;

			div{
				margin: 1em 0;
			}
		}

		button.modify{
			display: block;
		}
	}
}

.link {
	text-decoration: none !important;
	transition: .3s;
	font-size: 16px;
	border-bottom: 0px solid rgba(0,0,0, 0.0);
	padding-bottom: 1px;
	&:hover,
	&:focus {
		color: black;
		outline: none;
		border-bottom: 1px solid black;
	}
}

#overview {
	position: fixed;
	top: 70px;
	right: 10%;
	width: 316px;

	.box {
		box-shadow: 0 2px 24px rgba(0,0,0, 0.14);
		padding: 20px;
	}
	.price {
		font-size: 16px;
		margin-top: 5px;
		margin-bottom: 20px;
	}
	.split {
		border: 1px solid black;
		margin-right: -5px;
		margin-left: -5px;
		margin-bottom: 10px;
		margin-top: 20px
	}
	button {
		border: solid black 2px;
		padding: 12px;
		font-weight: bold;
		margin-top: 20px;
		margin-bottom: 15px;
		border-radius: 0px !important;
	}
	
	.disclaimer {
		padding-top: 30px;
	}
	
	.disclaimer-content {
		transition: .2s;
		opacity: 1;
	}

	.disclaimer-content.faded {
		opacity: 0.15;
	}

	@media screen and (max-width: 1370px) {
		right: 5%;
	}
	@media screen and (max-width: @col-md-min) {
		position: initial;
		width: 90%;
		max-width: 400px;
		margin: auto;
		margin-bottom: 150px;
	}
}

.btn.cartNav{
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	z-index: 10;
	font-size: 2em;
	font-family: @serifFont;
	text-transform: initial;
	opacity: 1;
	transition: opacity 0.1s linear;

	&.slick-disabled{
		opacity: 0.3;
	}
	span{
		display: none;
	}
}

#cartNavPrev{
	left: 0;
	padding-left: inherit;
	float: left;
}

#cartNavNext{
	right: 0;
	padding-right: inherit;
	float: right;
}

#sideImg{
	height: 100px;
}

#sum{
	text-align: right;
	padding-top: 1.5em;
	padding-bottom: 1.5em;

	p{
		margin: 1em 0;

		&.total{
			font-weight: 700;
		}
	}
}

.price{
	font-weight: bold;
}

@media screen and (min-width: @col-sm-min){
	#cartCaroussel {
		.item .item-image svg{
			margin-right: 0;
			height: 40vh;
		}
	}
}

@media screen and (min-width: @col-md-min){

	#cartNavPrev, #cartNavNext {
		&.slick-disabled{
			opacity: 0;
		}

		span{
			display: inline;
		}
	}

	#sideImg{
		height: 100vh;
	}

	#sum{
		position: absolute;
		bottom: @navbar-bottom + @navbar-height;
	}
}
