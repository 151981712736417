/* Copyright Svenni Dal - Sveinn Dal Bjornsson - mán apr 20 22:01:14 GMT 2015 */

@menu-backdrop-color: #1E0902;
@menu-backdrop-opacity: 0.5;

@error-color: #e40f0f;

@col-md-offset-1: 8.33333333%;
@col-md-4: 33.33333333%;
@col-md-11: 91.66666667%;

@col-sm-min: 768px;
@col-md-min: 992px;
@col-lg: 1200px;

@font-basesize: 16px;
@font-ratio: 1.25;
@baseHierarchyIndex: 6;

@serifFont: 'Forum', serif;
@sansSerifFont: proxima-nova, sans-serif;
@helveticaFont: 'Helvetica', sans-serif;

@import (inline) "../../node_modules/photoswipe/dist/photoswipe.css";
@import (inline) "../../node_modules/photoswipe/dist/default-skin/default-skin.css";

html, body{
	-moz-font-smoothing: antialiased;
	-webkit-font-smoothing: antialiased;
	font-family: @sansSerifFont;
	font-weight: 300;
	font-size: @font-basesize;
}

.bg-img {
  //background: url(/img/IMG_7381.jpg) no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

h1, h2, h3, h4, h5, h6 {
	font-family: @serifFont;
	font-weight: 400;

	&.uppercase {
		text-transform: uppercase;
	}
	&.bold {
		font-weight: bold;
	}
	&.seri {
		font-family: @sansSerifFont;
	}
}

.generate-headlineSizes();

.generate-headlineSizes(@index: 1) when (@index <= @baseHierarchyIndex) {
  .generate-headlineSizes((@index + 1));
  h@{index}{
	  .getFontSize(@index);
  }
}

.getFontSize(@hierarchy){
	font-size: unit(pow(@font-ratio, @baseHierarchyIndex - @hierarchy),em);
}

h1 p{
	font-family: @sansSerifFont;
	font-size: unit(1/pow(@font-ratio, 5),em); // go back to base fontsize
	font-weight: 400;
	margin: 0;
}

svg{
	width: 100%;
}

a{
	color: black;

	&:hover{
		text-decoration: none;
	}
}

/*
although the disabled tag is not a valid html tag, this follows the
implementation from http://stackoverflow.com/questions/10276133/how-to-disable-html-links
*/
a[disabled] {
    pointer-events: none;
}

nav a{
	font-family: @serifFont;
	.getFontSize(5);
}

button{
	transition: .2s;
	font-family: @sansSerifFont;
	background-color: black;
	color: white;
	border: 0;
	text-transform: uppercase;
	width: 100%;
	height: 48px;

	&:hover, &:focus{
		color: black;
		background-color: white;
		border: solid black 2px;
	}
}

button.inverted{
	color: black;
	background-color: white;
	border: solid black 2px;

	&:hover, &:focus{
		color: white;
		background-color: black;
		border: solid black 2px;
	}
}

button.btn{
	border-radius: 0;
	font-size: 1em;
	font-weight: 700;
}

input, select, textarea{
	width: 100%;
	margin: 16px 0;
	display: block;
	background-color: white;
	border: 1px solid #D9D9D9;
	border-radius: 0;
	padding-left: 7px;

	&::-webkit-input-placeholder{
		color: #B6B6B6;
	}
	&::-moz-placeholder{
		color: #B6B6B6;
	}
	&::-moz-placeholder{
		color: #B6B6B6;
	}
	&::-ms-input-placeholder{
		color: #B6B6B6;
	}
	&::placeholder{
		color: #B6B6B6;
	}
}

input, select, textarea, button[type="submit"]{
	display: block;
	margin: 16px 0;
}

#addToCartButton {
	margin-top : 40px;
}

input, select{
	height: 48px;
}

textarea{
	resize: none;
}

// remove dropdown styling but keep arrows
select{
	background: url(data:image/svg+xml;base64,PHN2ZyBpZD0iTGF5ZXJfMSIgZGF0YS1uYW1lPSJMYXllciAxIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA0Ljk1IDEwIj48ZGVmcz48c3R5bGU+LmNscy0xe2ZpbGw6I2ZmZjt9LmNscy0ye2ZpbGw6IzQ0NDt9PC9zdHlsZT48L2RlZnM+PHRpdGxlPmFycm93czwvdGl0bGU+PHJlY3QgY2xhc3M9ImNscy0xIiB3aWR0aD0iNC45NSIgaGVpZ2h0PSIxMCIvPjxwb2x5Z29uIGNsYXNzPSJjbHMtMiIgcG9pbnRzPSIxLjQxIDQuNjcgMi40OCAzLjE4IDMuNTQgNC42NyAxLjQxIDQuNjciLz48cG9seWdvbiBjbGFzcz0iY2xzLTIiIHBvaW50cz0iMy41NCA1LjMzIDIuNDggNi44MiAxLjQxIDUuMzMgMy41NCA1LjMzIi8+PC9zdmc+) no-repeat 95% 50%;
	-moz-appearance: none;
	-webkit-appearance: none;
	appearance: none;
}

select.empty{
	color: #B6B6B6;
}

label{
	font-weight: 300;
}

.toLeft{
    position: absolute;
	top: 0;
	left: 0;
}

.toRight{
	position: absolute;
	top: 0;
	right: 0;
}

.fixed {
	position: fixed;
	left: 0;
	top: 0;
}

.centerVertical{
	top: 50%;
	transform: translateY(-50%);
}

.thickGutter{
	padding: 25px 25px 0 25px;
}

.scrollableContent:last-of-type{
	padding-bottom: 1px;	// to prevent margin collapse between children
	margin-bottom: @navbar-height-mobile;	// to ensure that content doesn't disappear behind navbar;
}

i.icon:before{
	padding-right: 0.2em;
}

i.icon.big:before{
	.getFontSize(5);
}

i.icon.bigger:before{
	.getFontSize(3);
	&:before{
		.getFontSize(3);
	}
}

i.icon.last:before{
	padding-right: 0;
	padding-left: 0.2em;
}

.centerIconVertical > i:before,
.centerIconVertical > span,
.centerIconVertical > a{
	vertical-align: middle;
}

.fullSizeImg{
	background-repeat: no-repeat;
	background-position: center center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
}

button.noButtonStyle{
	color: black;
	font-weight: inherit;
	border: 0;
	outline:none;
	background: none;
	text-transform: none;
	-webkit-tap-highlight-color: rgba(255, 255, 255, 0);
	-webkit-tap-highlight-color: transparent;  // i.e. Nexus5/Chrome and Kindle Fire HD 7''
	width: initial;

	&:hover, &:focus, &:active, &:active:focus{
		border: 0;
		outline:none;
		background-color: transparent;
		box-shadow: none;
		-webkit-box-shadow: none;
	}
}

.loader {
	position: fixed;
	left: 0px;
	top: 0px;
	width: 100%;
	height: 100%;
	z-index: 1049;
	background: center no-repeat #fff;
}

#cart-message {
	height : 100px;
	width : 20vw;
	position : fixed;
	top : 30vh;
	left : 40vw;
	opacity : 0;
	border : solid 2px black;
	@media(max-width : @col-md-min){
		width : 90vw;
		left : 5vw;
		top : 10vh;
	}
	background-color : white;
	#cart-text {
		padding-top : 30px;
		text-align : center;
	}
}

.info-page {
	padding-top: 40px;
	padding-bottom: 300px;
	h1 {
		margin-bottom: 60px;
	}
	h4, p {
		margin-bottom: 20px;
	}
	p {
		font-size: 16px;
	}
}

.css-full-height {
	height: 100%;
	margin-bottom: 150px;
}

#back-top {
	z-index: 3000;
	transition: .3s;
	position: absolute;
	top: 5px;
	margin-left: 130px;
	display: block;
	svg {
		height: 14px;
		width: 14px;
	}
	h5 {
		margin-left: 10px;
		display: inline-block;
	}
	&.cart {
		margin-left: calc(~'25% + 15px');
	}
	&.checkout {
		margin-left: 150px;
	}

	svg {
		transition: .3s;
		padding-left: 8px;
	}

	&:hover {
		color: black;
		svg {
			padding-left: 0px;
		}
	}
	@media screen and (max-width: @col-md-min) {
		margin-left: 0px;
		&.cart {
			margin-left: 15px;
		}
	}
}

@media screen and (min-width: @col-sm-min){
	.scrollableContent:last-of-type{
		margin-bottom: @navbar-height;	// to ensure that content doesn't disappear behind navbar;
	}

	nav a{
		font-family: @serifFont;
		.getFontSize(3);
		&.cart-icon {
			.getFontSize(2);
		}
	}
}

@media screen and (min-width: @col-md-min){
	.thickGutter{
		padding: 50px 25px 0 25px;
	}
	input, select{
		height: 33px;
	}
	button{
		height: initial;
	}
	.scrollableContent,
	.scrollableContent:last-of-type{
		overflow: auto;
		height: 100%;
		margin-bottom: 0;
		padding-bottom: 0px;
	}

	.fullHeight .scrollableContent,
	.fullHeight .scrollableContent:last-of-type{
		padding-bottom: 70px;	// to ensure that content doesn't disappear behind navbar;
	}
}

.fullHeight.scrollableContent.last {
	//padding-bottom: 70px;	// to ensure that content doesn't disappear behind navbar;
	@media(min-width : 1000px){
		margin-bottom: 170px;	// to ensure that content doesn't disappear behind navbar;
	}
}

.hidden {
	display: none;
}

@import "navigation_main";
@import "products";
@import "product_detail";
@import "cart";
@import "page";
@import "about";
@import "contact";
@import "checkout";
@import "faq";
@import "frontpage";
@import "modal";
@import "locations";
