.page{
	p{
		margin-top: 37px;
		margin-bottom: 37px;
	}

	h1 p{
		margin-top: initial;
		margin-bottom: initial;
	}
}

@media screen and (min-width: @col-md-min){
	.page{
		margin-top: 10vh;
	}
}
