.page #aboutLogo{
	//width: 55%;
	svg{
		//width: 100%;
		width: 55%;
		height: 55%;
		margin: 0 auto;
		display: block;
		margin-top: 30px;
	}
}

#contact-us-button {
	width: 60%;
	height: 40px !important;
	font-weight: bold;
	border-radius: 0px !important;
}
