#locations-block {
    padding: 100px;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 100px;
    margin-bottom: 200px;
    @media screen and (max-width: 1366px) {
        grid-template-columns: repeat(3, 1fr);
        margin-bottom: 100px;
    }
    @media screen and (max-width: 768px) {
        grid-template-columns: repeat(2, 1fr);
        margin-bottom: 100px;
    }
    @media screen and (max-width: 375px) {
        padding: 30px;
        grid-template-columns: 1fr;
    }
    .location-item {
        width: 100%;
        transition: .5s;
        -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
        filter: grayscale(100%);
        @media screen and (max-width: 768px) {
            -webkit-filter: grayscale(0%); /* Safari 6.0 - 9.0 */
            filter: grayscale(0%);
        }
        &:hover {
            -webkit-filter: grayscale(0%); /* Safari 6.0 - 9.0 */
            filter: grayscale(0%);
            transform: scale(1.05);
        }
    }
}