.question-container {
	padding: 30px 0px;
	position: relative;
	border-bottom: 1px solid rgb(232, 232, 232);
	.toggler {
		float: right;
		padding: 0px 15px;
		.arrow {
			width: 30px;
			transition: .2s;
			margin-top: 10px;
			&.up {
				transform: rotateX(180deg);
			}
		}
	}

	transition: .3s;
	&:hover {
		cursor: pointer;
		background-color: rgba(0,0,0,0.01);
	}
	.question {
		font-size: 20px;
		font-weight: bold;
	}
	.answer {
		padding-top: 30px;
		font-size: 16px;
	}
}
.splitter {
	border-bottom: 1px solid rgb(232, 232, 232);
}