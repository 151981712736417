.module-x {
	width: 20px;
	position: absolute;
	z-index: 99;
}

#confirmation-modal,
#already-in-cart-modal {
	h3 {
		text-align: center;
		padding: 15px;
	}
	.modal-trigger {
		&:hover {
			cursor: pointer;
		}
	}
	.j-modal-body {
		.info-content {
			.product-added-text {
				font-weight: bold;
			}
			.product-added-title {
				font-family: 'Forum', serif;
				font-size: 26px;
				margin: 15px 0px;
			}
			.product-size-text,
			.product-quantity-text,
			.product-price-text {
				* {
					display: inline-block;
				}
				.em {
					font-weight: bold;
				}
			}
			.product-price-text {
				margin: 25px 0px;
				* {
					font-weight: bold;
				}
			}
			.white {
				margin-top: 10px;
				background-color: white;
				color: black;
				border: 2px solid black;
				&:hover {
					background-color: black;
					color: white;
				}
			}
			button {
				border-radius: 0px !important;
				border: 2px solid black;
				font-weight: bold;
				font-size: 1em;
				padding-top: 5px;
				padding-bottom: 5px;
			}
		}
		.img-container {
			img {
				margin-top: 30px;
			}
		}
	}
}
#confirmation-modal {
	@media screen and (max-width: @col-md-min){
		.info-content {
			padding-top: 30px;
		}
	}
}



#imageContainer{
	height: calc(~'100vh - 160px');
	@media screen and (min-width: @col-md-min) and (min-height: 830px){
		height: calc(~'100vh - 210px');
	}
	@media screen and (max-width: @col-md-min){
		height: initial;
	}

	a.fullSizeImg{
		display: none;
		height: 100%;
		overflow: hidden;
	}

	.picture{

		a {
			position: relative;
			width: 100%;
			height: 400px;
			display: block;
			overflow: hidden;
		}
		a img {
			position: absolute;
			left: 50%;
			height: 100%;
			width: auto;
			-webkit-transform: translateX(-50%);
			-ms-transform: translateX(-50%);
			transform: translateX(-50%);
		}
	}
	@media screen and (max-width: @col-md-min) {
		padding-top: 50px;
	}
}

#productDetails{
	// display: flex;
	// flex-direction: column;
	// justify-content: space-between;
	h1 {
		font-size: 69px;
		margin-top: 5px;
		margin-bottom: 30px;
	}
	.price {
		font-size: 20px;

	}
	.sub-info {
		font-size: 14px;
		font-style: italic;
		margin-bottom: 10px;
	}
	.price-info {
		font-size: 16px;
		font-weight: bold;
		margin-bottom: 15px;
	}

	em{
		font-style: normal;
		font-weight: 400;
	}
	>p{
		margin-top: 37px;
		margin-bottom: 37px;
	}
	label{
		display: block;
	}
	select,
	input {
		margin: 5px 0px;
	}
	button[type='submit']{
		margin-top: 47px;
	}
}

.slick-dots {
    position: absolute;
    bottom: 17px;

    display: block;

    width: 100%;
    padding: 0;
    margin: 0;

    list-style: none;

    text-align: center;
	li {
	    position: relative;

	    display: inline-block;

	    width: 10px;
	    height: 10px;
	    margin: 0 5px;
	    padding: 0;

	    cursor: pointer;
		button {
		    font-size: 0;
		    line-height: 0;

		    display: block;

		    width: 10px;
		    height: 10px;
		    padding: 5px;

		    cursor: pointer;

		    color: transparent;
		    border: 0;
		    outline: none;
		    background: transparent;

			&:hover, &:focus{
	    		outline: none;

				&:before{
					opacity: 1;
				}
			}

			&:before{
			    // font-family: 'slick';
			    font-size: 6px;
			    line-height: 10px;

			    position: absolute;
			    // top: 0;
			    // left: 0;
				//
			    // width: 20px;
			    // height: 20px;
				//
			    // content: 'â€¢';
			    text-align: center;

			    opacity: .25;
			    color: black;

			    -webkit-font-smoothing: antialiased;
			    -moz-osx-font-smoothing: grayscale;

				width: 6px;
				height: 6px;
				top: 2px;
				left: 2px;
				content: '';
				border-radius: 10px;
				background-color: white;
			}
		}

		&.slick-active button:before{
			opacity: .75;
		    color: black;
			background-color: white;
		}
	}
}

@media screen and (min-width: @col-md-min) and (max-height: 870px){
	#productDetails{
		>p{
			margin-top: 0;
			margin-bottom: 0;
		}
		button[type='submit']{
			margin-top: 0;
		}
	}
}

@media screen and (min-width: @col-md-min){
	#imageContainer{

		a.fullSizeImg{
			display: block;
		}

		.hideScrollbar{
			width: 130px;
			height: 100%;
			max-height: 100%;
			overflow: hidden;
			float: left;
			box-sizing: content-box;
		}

		.picture{
			width: 100%;
			height: 100%;
			max-height: 100%;
			padding-right: 17px; // set with javascript
			overflow-y: auto;
			overflow-x: hidden;
			box-sizing: content-box;

			&::-webkit-scrollbar {
    			display: none;
			}
			// overflow-y: auto;
			// overflow-x: hidden;
			//float: left;

			a{
				margin: 12px;
				display: block;
				width: 105px;
				height: 105px;

				img{
					width: auto;
					height: 100%;
				}

				&:first-child{
					margin-top: 0;
				}
			}
		}
	}
}
