// Copyright 2017, Svenni Dal(Sveinn Dal Bjornsson) & //JÖKULÁ, All rights reserved Wed Dec 13 16:27:19 GMT 2017
.inline {
	display : inline;
	width : 10%;
	margin-bottom : 15px !important;
}

#cartSummary {
	border: 2px solid black;
	margin: 20px 0;
	padding: 15px 30px;

	.value{
		float: right;
		text-align: right;
	}

	hr{
		border: 1px solid black;
		width: 100px
	}

	p{
		margin-top: 7px;
		margin-bottom: 7px;
	}
}

#shippingAddress {
	font-family: @sansSerifFont;
	font-size: @font-basesize;
	font-weight: bold;
}

#checkout {
	overflow-x: hidden;
	.title {
		font-family: @helveticaFont;
		font-size: 25px;
		font-weight: bold;
		margin-top: 40px;
	}
	.payment-title {
		position: relative;
		bottom: 12px;
		left: 5px;
	}
}

#order-form {
	width: initial;
	padding-bottom: 50px;
	font-family: @helveticaFont;
	.discountWrapper{
		display: flex;
	}

	input,
	select {
		height: 40px;
		option {
			color: #333333;
		}
		&:disabled {
			opacity: 0.3;
		}
	}

	#discount-code {
		margin-top: 0;
	}

	#discount-button {
	    width: initial;
	    padding: 3px 9px;
	}

	.invalid {
		border: 1px solid @error-color;
	}
	.pickup-box {
		visibility: hidden;
		pointer-events: none;
		padding-top : 20px;
		margin-bottom: 30px;
		input {
			display : inline;
			width : 30px;
			height : 1em;
		}
		p {
			display : inline;
		}
		a {
			display : inline;
			text-decoration : underline;
		}
	}
	.accept-box {
		padding-top : 20px;
		input {
			display : inline;
			width : 30px;
			height : 1em;
		}
		p {
			display : inline;
		}
		a {
			display : inline;
			text-decoration : underline;
		}
		&.terms-error {
			color: red;
		}
	}
}

@media screen and (min-width: 992px) {
	#order-form #discount-button {
		height: 33px;
	}

	#cartSummary hr {
		margin-left: 0;
	}
}
