@modal-backdrop: rgba(255, 255, 255, 0.8);

.j-modal {
	position: fixed;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;
	display: none;
	justify-content: center;
	align-items: center;
	background-color: @modal-backdrop;
	overflow-y: scroll;
	z-index: 999;
	opacity: 0;
	transition: opacity .5s;
	&.shown {
		display: flex;
		opacity: 1;
		animation: show-modal;
		animation-duration: .5s;
		animation-fill-mode: forwards;
	}
	.j-modal-body {
		width: 400px;
		max-width: 95%;
		background-color: white;
		padding: 15px;
		border: 2px solid black;
		max-height: 80vh;
	}
}

@keyframes show-modal {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}